import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const MySkills = () => {
  const isAboveMediumScreens = useMediaQuery("(min-width:1060px)");

  return (
    <section id="skills" className="pt-10 pb-24">
      {/** HEADER AND IMAGE SECTION */}
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-4xl mb-5">
            My <span className="text-red">SKILLS</span>
          </p>
          <LineGradient width="w-1/3" />
          <p className="mt-10 mb-7"></p>
        </motion.div>

        <div className="mt-16 md:mt-0">
          {isAboveMediumScreens ? (
            <div
              className="relative z-0 ml-20 before:absolute before:-top-20 before:-left-10 
            before:w-full before:h-full before:border-2 before:border-blue before:z-[-1]"
            >
              <img
                alt="skills"
                className="z-10"
                src="assets/skills-image.png"
              />
            </div>
          ) : (
            <img alt="skills" className="z-10" src="assets/skills-image.png" />
          )}
        </div>
      </div>

      {/* SKILLS */}
      <div className="md:flex md:justify-between mt-16 gap-32">
        {/* Technical Skills */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">01</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Technical Skills
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-blue absolute right-0 top-0 z-[-1]" />
          </div>
          <div className="skills-section mt-5">
            <p>
              <strong>Languages & Frameworks:</strong> Java, Spring Boot, HTML,
              CSS, JavaScript (Angular, React, Vue.js)
            </p>
            <p>
              <strong>Databases:</strong> PostgreSQL, MongoDB
            </p>
            <p>
              <strong>Tools & Platforms:</strong> Swagger, Express, Bootstrap,
              Jest, Cypress
            </p>
          </div>
        </motion.div>

        {/* Professional Experience */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">02</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Professional Experience
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-red absolute right-0 top-0 z-[-1]" />
          </div>
          <div className="professional-experience-section mt-5">
            <p>
              <strong>E-commerce Website:</strong> Developed a full-stack
              application using [Tech Stack]. Focused on [Your Contribution].
            </p>
            <p>
              <strong>Golf-Tracker Website:</strong> Implemented [Key Features]
              using [Tech Stack]. Role: [Your Role].
            </p>
            <p>
              <strong>Tenmo Application:</strong> Contributed to [Project
              Aspect] using [Tech Stack].
            </p>
          </div>
        </motion.div>
        {/* Soft skills and achievements */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">03</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Soft Skills and Achievements
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-yellow absolute right-0 top-0 z-[-1]" />
          </div>
          <div className="soft-skills-section mt-5">
            <p>
              <strong>Collaboration and Teamwork:</strong> Successfully
              collaborated in diverse team environments at Slalom LLC and
              Breathe Mongolia NGO.
            </p>
            <p>
              <strong>Quality and Punctuality:</strong> Committed to delivering
              high-quality work within deadlines.
            </p>
            <p>
              <strong>Continuous Learning:</strong> Actively seeking new
              challenges and learning opportunities in the tech industry.
            </p>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default MySkills;
